import { callAPI } from '#root/utils/helpers/helperApi'

export const getValidatePhone = ({ phoneNumber }) =>
  callAPI({
    url: '/telephony/validate-phone',
    method: 'get',
    params: {
      phoneNumber
    }
  })
